import './styles/Title.css';

function Title() {
    return (
        <div className="Title">
            <h1><span className="Title-span">Human</span>GPT</h1>
        </div>
    );
}

export default Title;